<template>
	<v-container class="fill-height justify-center">
		<v-sheet class="d-flex align-center text-center px-sm-10 py-sm-8">
			<img src="@/assets/logo-small.svg" alt="Blueprint Portal logo" />

			<v-carousel :show-arrows="false" hide-delimiter-background :cycle="true" height="425px">
				<v-carousel-item>
					<img src="@/assets/group-160.svg" />
					<h3>
						With Blueprint Portal, you have access to review claims, health plan information and
						more. 24 hours a day. 7 days a week.
					</h3>
				</v-carousel-item>
				<v-carousel-item>
					<img src="@/assets/group-165.svg" />
					<h3>
						With Blueprint Portal, you can take your ID card with you. Whenever you need it.
						Wherever you go.
					</h3>
				</v-carousel-item>
			</v-carousel>
			<div class="btn-wrapper flex-wrap d-flex w-100">
				<v-btn name="login" variant="flat" class="flex-1-0 primary" height="48" @click="signInCta">
					Sign In
				</v-btn>
				<v-btn
					name="register"
					variant="outlined"
					class="flex-1-0 secondary"
					height="48"
					color="#575757"
					@click="$router.push('/register')"
				>
					Register
				</v-btn>
			</div>
		</v-sheet>
	</v-container>
</template>

<script>
import qs from 'qs'
import { isTokenValid } from '@/services/api/axios.js'
import { logout } from '@/services/api/axios.js'

export default {
	created() {
		logout()
	},
	methods: {
		signInCta() {
			function dec2hex(dec) {
				return ('0' + dec.toString(16)).substr(-2)
			}

			function randomCode() {
				var array = new Uint32Array(56 / 2)
				window.crypto.getRandomValues(array)
				return Array.from(array, dec2hex).join('')
			}

			function sha256(plain) {
				// returns promise ArrayBuffer
				const encoder = new TextEncoder()
				const data = encoder.encode(plain)
				return window.crypto.subtle.digest('SHA-256', data)
			}

			function base64urlencode(a) {
				var str = ''
				var bytes = new Uint8Array(a)
				var len = bytes.byteLength
				for (var i = 0; i < len; i++) {
					str += String.fromCharCode(bytes[i])
				}
				return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
			}

			async function challenge_from_verifier(v) {
				const hashed = await sha256(v)
				const base64encoded = base64urlencode(hashed)
				return base64encoded
			}

			if (isTokenValid()) {
				this.$router.push('/select-plan')
			} else {
				this.$LoadingTakeover(true)
				const redirect_uri = window.location.origin + '/auth-success'
				const uri = qs.stringify({ redirect_uri })
				const state = Math.random().toString(36).substring(2, 12)

				const code_verifier = randomCode()

				challenge_from_verifier(code_verifier).then((result) => {
					sessionStorage.setItem('code_verifier', code_verifier)
					location.href = `${import.meta.env.VITE_OIDC_AUTH_URL}/authorize?client_id=${
						import.meta.env.VITE_CLIENT_KEY
					}&scope=openid%20profile%20offline_access&${uri}&response_type=code&state=${state}&code_challenge=${result}&code_challenge_method=S256`

					return result
				})
			}
		}
	},
	mounted() {
		Five9ChatTerminate()

		const observer = new PerformanceObserver((list) => {
			list.getEntries().forEach((entry) => {
				if (entry.type === 'back_forward') {
					this.$LoadingTakeover(false)
				}
			})
		})
		observer.observe({ type: 'navigation', buffered: true })
	}
}
</script>

<style lang="scss" scoped>
.v-sheet {
	box-shadow: 0 20px 24px -4px #10182814;
	flex-direction: column;
	gap: 24px;
}

:deep(.v-responsive__content) {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 24px;
}

.btn-wrapper {
	gap: 12px;
}

.v-carousel-item {
	img {
		max-width: 14rem;
	}
}

:deep(.v-carousel__controls) {
	.v-btn.v-btn--icon {
		height: 9px;
		width: 9px;
		margin: 0 6px;
		opacity: 0.3;

		&.v-btn--active {
			color: $primary-700;
			opacity: 1;

			&:hover,
			&:focus {
				opacity: 1;
			}
		}

		&:hover,
		&:focus {
			opacity: 0.8;
		}
	}
}

@media only screen and (max-width: 600px) {
	.v-sheet {
		.v-btn {
			width: 100%;
		}
	}
}
</style>
